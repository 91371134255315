<template>
  <div class="cardbox">
    <div
      class="card__box"
      :style="{
        'border-color': borderColor,
        backgroundColor: backgroundColor,
        'border-radius': '12px',
      }"
    >
      <h6
        class="pb-3"
        :style="{ color: titleColor, 'text-align': title_alignment }"
      >
        {{ title }}
      </h6>

      <slot name="conteudo" />

      <slot name="footerImage" />
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "title",
    "titleColor",
    "borderColor",
    "title_alignment",
    "backgroundColor",
  ],
};
</script>

<style scoped>
h6 {
  font-size: 1em;
  color: red;
}

@media (max-width: 600px) {
  h6 {
    font-weight: 600;
  }

  p {
    font-size: 1em;
  }
}
</style>
