<template>
  <div>
    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="5"
          class="d-flex flex-column justify-center align-center justify-md-end align-md-start"
        >
          <v-img
            max-width="65%"
            src="../../../assets/sobre_bento_tradicao_resistencia_04.png"
            alt=""
          ></v-img>
          <p
            style="font-size: 0.5em; width:65%; text-align: center;"
            class="pa-2"
          >
            Registro fotográfico da procissão da Festa de Nossa Senhora das
            Mercês na comunidade atingida. Foto: Flora Passos, 2019.
          </p>
          <v-img
            max-width="65%"
            src="../../../assets/sobre_bento_tradicao_resistencia_05.png"
            alt=""
          ></v-img>
          <p
            style="font-size: 0.5em; width:65%; text-align: center;"
            class="pa-2"
          >
            Andores com as imagens de São Bento e de Nossa Senhora das Mercês
            carregados pelos moradores na procissão da Festa de Nossa Senhora
            das Mercês no território destruído. Foto: Flora Passos, 2019.
          </p>
        </v-col>
        <v-col cols="12" md="7">
          <Card
            borderColor="#e06919"
            class="mb-5"
            title="Tradição e resistências"
            titleColor="#000"
          >
            <template v-slot:conteudo>
              <p>
                A partir do rompimento da barragem de Fundão, as pessoas que
                faziam parte das comunidades situadas no território de Mariana,
                incluindo Bento Rodrigues, passaram a integrar um grupo social
                específico como atingido por empreendimento cujo reconhecimento
                legal estabelece algum tipo de ressarcimento ou indenização,
                reabilitação ou reparação. Essa categoria foi criada devido ao
                grande número de barragens construídas no Brasil a partir da
                década de 1950, relacionada à construção de diversas usinas
                hidrelétricas no território brasileiro.
              </p>
              <p>
                Embora essa categoria tenha sido criada para tratar de casos
                envolvendo empreendimentos hidrelétricos, ela representa uma
                categoria social em disputa. A noção de atingido varia de acordo
                com a realidade política e cultural, ampliando-se para além do
                impacto da remoção planejada, abrangendo a circunstância de
                desastres envolvendo barragens.
              </p>
              <p>
                As pessoas atingidas, em consequência da tragédia, enfrentam uma
                situação extrema de partida do território, sendo obrigadas a
                abandonarem suas terras, seus costumes e suas tradições. São
                forçadas a se adaptarem a uma nova realidade e a um novo lugar,
                além de reconstruírem seus laços familiares e sociais em um novo
                território.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                As construções, as maneiras de se fazer, as festas, o
                artesanato, a música, a paisagem, o que foi deixado pelas
                gerações anteriores, fazem parte do patrimônio cultural de um
                grupo de pessoas, quando entendem que tudo isso pertence a elas
                e que fazem parte desse conjunto de indivíduos, ou seja, tudo
                isso faz parte de suas identidades. Assim, resistir e lutar pelo
                reconhecimento público desse patrimônio significa preservar a
                própria existência e a do grupo do ponto de vista social e
                cultural.
              </p>
              <p>
                Ao defenderem seu patrimônio e suas tradições, as pessoas
                atingidas de Bento Rodrigues lutam pela preservação de suas
                identidades, mantidas pela memória do espaço e através da
                continuação das celebrações e rituais que ocorriam no território
                destruído.
              </p>
              <p>
                Cabe ressaltar que juntamente com a Capela de São Bento e os
                caminhos anteriormente percorridos, os objetos litúrgicos usados
                nas celebrações também foram perdidos, a exemplo das imagens dos
                santos, castiçais, âmbula, crucifixo e cálice. Desta forma, a
                retomada dos ritos religiosos fica ainda mais difícil para esses
                moradores ligados à tradição religiosa católica, que perderam
                todas as suas referências. Essa situação precisa ser
                considerada, visto que a perda das referências identitárias traz
                um vazio quanto ao lugar do sujeito no mundo.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex flex-column justify-center align-center justify-md-end align-md-end"
        >
          <v-img
            max-width="65%"
            src="../../../assets/sobre_bento_tradicao_resistencia_06.png"
            alt=""
          ></v-img>
          <p
            style="font-size: 0.5em; width:65%; text-align: center;"
            class="pa-2"
          >
            As atingidas Sandra e Mônica Quintão à frente do andor da imagem de
            Nossa Senhora das Mercês. Fonte: Flora Passos, 2019.
          </p>
          <v-img
            max-width="65%"
            src="../../../assets/sobre_bento_tradicao_resistencia_07.png"
            alt=""
          ></v-img>
          <p
            style="font-size: 0.5em; width:65%; text-align: center;"
            class="pa-2"
          >
            Moradores da comunidade voltam ao território atingido para celebrar
            a Festa de Nossa Senhora das Mercês. Fonte: Flora Passos, 2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="5"
          class="d-flex flex-column justify-center align-center justify-md-start align-md-start"
        >
          <v-img
            max-width="65%"
            src="../../../assets/sobre_bento_tradicao_resistencia_08.png"
            alt=""
          ></v-img>
          <p
            style="font-size: 0.5em; width:65%; text-align: center;"
            class="pa-2"
          >
            Celebração da Festa de São Bento realizada em 2017 na comunidade
            arruinada.Fonte: Jornal A Sirene, Ed. 52, agosto de 2020.
          </p>
          <v-img
            max-width="65%"
            src="../../../assets/sobre_bento_tradicao_resistencia_09.png"
            alt=""
          ></v-img>
          <p
            style="font-size: 0.5em; width:65%; text-align: center;"
            class="pa-2"
          >
            Celebração da Festa de Nossa Senhora das Mercês na comunidade
            arruinada. Fonte: Jornal A Sirene, Ed. 7, outubro de 2016. Autor:
            Genival Pascoal.
          </p>
        </v-col>
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                No ano seguinte ao do rompimento, realizar os ritos com objetos
                que não eram da comunidade, em ruas que não eram suas, despertou
                nas pessoas atingidas a consciência de pertencimento ao
                território, levando-os a voltar para Bento Rodrigues mesmo em
                ruínas e ali fazer suas celebrações. Apesar da devastação do
                território, trata-se do lugar ao qual os atingidos se sentem
                pertencentes, e por isso, a atmosfera dos ritos religiosos
                quando realizados no local de origem dessas celebrações,
                mantém-se ligada à identidade da comunidade.
              </p>
              <p>
                Esse sentimento uniu os moradores no engajamento pela luta para
                que as festas religiosas fossem realizadas nos mesmos espaços em
                que ocorriam antes do desastre criminoso. No ano de 2016, os
                atingidos fizeram no dia 30 de julho a festa de São Bento no
                território de Bento Rodrigues, com procissão, celebração, queima
                de fogos e hasteamento da bandeira do padroeiro.
              </p>
            </template>
          </Card>

          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Considerando-se que a área atingida pela lama passou a ser
                administrada pela Samarco depois do rompimento, os moradores
                vivem em constante luta para terem o direito de manter a sua
                relação com o espaço que lhes pertence. Assim, além da festa de
                São Bento, os atingidos buscam também realizar outros ritos no
                território, como o do sepultamento.
              </p>
              <p>
                Os moradores passam a reocupar o território, e a partir do
                momento em que atuam sobre ele, se apropriando novamente, impõem
                seus protagonismos. Assim, além de afirmarem a quem pertence
                aquele solo e o que deve ser feito dele, os locais e os ritos
                são ferramentas culturais fundamentais para a reafirmação de sua
                identidade. Utilizar esse patrimônio para a manutenção da
                memória serve como mecanismo de resistência para que a história
                do território destruído não se perca, justamente pela relação
                que os moradores mantêm com o local, demonstrando a consciência
                de territorialidade.
              </p>
              <p>
                Assim Como nas outras comunidades atingidas pelo rompimento da
                barragem de Fundão, em Bento Rodrigues muitos moradores possuíam
                ligação com a igreja e as celebrações católicas desde muito
                novos. Havia o cuidado de preparar as procissões da Semana
                Santa, a festa do padroeiro, bem como as missas e esse zelo
                fazia parte do cotidiano das pessoas, como é o caso do Sr.
                Filomeno da Silva. Na sexta-feira da Paixão, muitas pessoas vão
                para Bento mesmo depois do desastre criminoso, sendo as missas
                celebradas na cobertura instalada sobre as ruínas da Capela de
                São Bento. Devido à perda de muitos objetos litúrgicos, várias
                celebrações foram interrompidas e as vias sacras não são mais
                realizadas como antes, sendo reduzido a menos de um terço o
                número de pessoas que frequentam.
              </p>
              <p style="text-align: center;">
                <strong>Autora: Ana Paula Alves Ferreira <sup>1</sup> </strong>
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="justify-center align-start">
        <v-col cols="12" md="3" class="d-flex flex-column align-start">
          <v-img
            max-width="100%"
            src="../../../assets/sobre_bento_tradicao_resistencia_10.png"
            alt=""
          ></v-img>
          <p
            style="font-size: 0.5em; width:100%; text-align: center;"
            class="pa-2"
          >
            Registro fotográfico feito em 1992, da celebração do casamento da
            atingida Marinalda Aparecida Silva Muniz e do atingido Manoel Marcus
            Muniz, na Capela de São Bento. Fonte: Jornal a Sirene, Ed. 22,
            janeiro de 2018. Álbum da Família.
          </p>
        </v-col>
        <v-col cols="12" md="3" class="d-flex flex-column align-start">
          <v-img
            max-width="100%"
            src="../../../assets/sobre_bento_tradicao_resistencia_11.png"
            alt=""
          ></v-img>
          <p
            style="font-size: 0.5em; width:100%; text-align: center;"
            class="pa-2"
          >
            Registro fotográfico feito em 23 de dezembro de 2017, da celebração
            das bodas de prata da atingida Marinalda Aparecida Silva Muniz e do
            atingido Manoel Marcus Muniz, na cobertura executada sobre as ruínas
            da capela. Fonte: Jornal a Sirene, Ed. 22, janeiro de 2018.
          </p>
        </v-col>
        <v-col cols="12" md="3" class="d-flex flex-column align-start">
          <v-img
            max-width="100%"
            src="../../../assets/sobre_bento_tradicao_resistencia_12.png"
            alt=""
          ></v-img>
          <p
            style="font-size: 0.5em; width:100%; text-align: center;"
            class="pa-2"
          >
            Registro fotográfico dos moradores na Festa de São Bento em
            2017,durante a confraternização na casa utilizada pelos “Loucos pelo
            Bento”. Fonte: Fonte: Jornal A Sirene, Ed. 52, agosto de 2020.
          </p>
        </v-col>
        <v-col cols="12" md="3" class="d-flex flex-column align-start">
          <v-img
            max-width="100%"
            src="../../../assets/sobre_bento_tradicao_resistencia_13.png"
            alt=""
          ></v-img>
          <p
            style="font-size: 0.5em; width:100%; text-align: center;"
            class="pa-2"
          >
            Moradores de Bento Rodrigues em frente à Capela de Nossa Senhora das
            Mercês em uma das ocasiões que retornaram à comunidade devastada
            pelo rejeito. Fonte: Jornal A Sirene, Ed. 38, maio de 2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="justify-center align-start">
        <v-col
          cols="12"
          md="3"
          class="d-flex flex-column align-center justify-center"
        >
          <v-img
            max-width="100%"
            src="../../../assets/sobre_bento_tradicao_resistencia_14.png"
            alt=""
          ></v-img>
          <p
            style="font-size: 0.5em; width:100%; text-align: center;"
            class="pa-2"
          >
            Registro fotográfico da primeira missa realizada nas ruínas da
            Capela de São Bento, depois de ser destruída pela lama. Fonte:
            Jornal A Sirene, Ed. 65, setembro de 2021.
          </p>
        </v-col>
        <v-col cols="12" md="3" class="d-flex flex-column align-start">
          <v-img
            max-width="100%"
            src="../../../assets/sobre_bento_tradicao_resistencia_15.png"
            alt=""
          ></v-img>
          <p
            style="font-size: 0.5em; width:100%; text-align: center;"
            class="pa-2"
          >
            Vista interna da Capela das Mercês durante preparativos para a
            celebração da festa em louvor a Nossa Senhora das Mercês. Foto:
            Flora Passos, 2019.
          </p>
        </v-col>
        <v-col cols="12" md="3" class="d-flex flex-column align-start">
          <v-img
            max-width="100%"
            src="../../../assets/sobre_bento_tradicao_resistencia_16.png"
            alt=""
          ></v-img>
          <p
            style="font-size: 0.5em; width:100%; text-align: center;"
            class="pa-2"
          >
            Registro fotográfico da banda passando em frente à ruína do Bar do
            Juca durante a procissão da Festa de São Bento no território
            atingido. Foto: Flora Passos, 2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p style="margin-bottom: 0;">
              <sup>1</sup> Arquiteta e Urbanista pela USP. Assessora Técnica na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container pb-10 mt-4">
      <v-row class="d-flex">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p><strong>Referências Bibliográficas</strong></p>

            <p>
              CASTRIOTA, Leonardo Barci. Dossiê de Tombamento de Bento
              Rodrigues. UFMG. Belo Horizonte, 2019. Disponível em:
              <a
                class="pink--text"
                style="text-decoration: none;"
                href="https://issuu.com/patrimonioculturalmpmg/docs/bentorodriguesdossietombamento"
                target="_blank"
              >
                https://issuu.com/patrimonioculturalmpmg/docs/bentorodriguesdossietombamento.</a
              >
              Acesso em: 30 mar. 2022.
            </p>

            <p>
              PÓLEN Consultoria Patrimônio e Projetos Ltda.
              <b
                >Diagnóstico preliminar dos bens culturais identificados no
                território atingido em Mariana pelo rompimento da barragem de
                Fundão</b
              >. Mariana, 2017.
            </p>

            <p>
              TEIXEIRA, Vanessa Cerqueira.<b>
                Fé e cultura barroca sob o manto mercedário: devoção,
                religiosidade e vivências a partir da irmandade de Nossa Senhora
                das Mercês de Mariana (1749-1822)</b
              >. 220 f. 2017. Dissertação (Mestrado em História) — Instituto de
              Ciências Humanas, Universidade Federal de Juiz de Fora Juiz de
              Fora-MG, 2017.)
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
export default {
  components: { Card },
  data() {
    return {};
  },
};
</script>

<style scoped>
.router-link-active {
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: #e06919;
}
section {
  margin-bottom: 3em;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
  border-radius: 8px;
}

.inside__card p {
  font-size: 0.5em;
}
</style>
